import React, { Component } from 'react';

import classname from 'classnames';

import Style from './FJChoiceness.module.less';

import FJSvg from '../../FJSvg/FJSvg';
import AIVideoGenerator from './AIVideoGenerator/AIVideoGenerator';
import TextToSpeech from './TextToSpeech/TextToSpeech';
import AIScript from './AIScript/AIScript';
import AIBGRemover from './AIBGRemover/AIBGRemover';
import AutoSubtitle from './AutoSubtitle/AutoSubtitle';
// import CurveSpeed from './CurveSpeed/CurveSpeed';
import Translate from './Translate/Translate';
import FJSrcUtil from '../../../src/util/FJUtil';

export default class FJChoiceness extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeIndex: 0,
    };
    this.choicenessLeftDom = React.createRef();
    this.choicenessRightDom = React.createRef();
  }

  __drawHTag() {
    const { hTag = 'h2', data = {} } = this.props;
    switch (hTag) {
      case 'h1':
        return <h1 className={classname(Style.title)}>{data.title}</h1>;
      case 'h2':
        return <h2 className={classname(Style.title)}>{data.title}</h2>;
      case 'h3':
        return <h3 className={classname(Style.title)}>{data.title}</h3>;
      case 'h4':
        return <h4 className={classname(Style.title)}>{data.title}</h4>;
      case 'h5':
        return <h5 className={classname(Style.title)}>{data.title}</h5>;
      case 'h6':
        return <h6 className={classname(Style.title)}>{data.title}</h6>;
      default:
        return <h2 className={classname(Style.title)}>{data.title}</h2>;
    }
  }

  __drawToolsList() {
    const { data = {} } = this.props;

    const itemClick = (item, index) => {
      this.setState({
        activeIndex: index,
      });
    };

    return (
      Array.isArray(data.tools) &&
      data.tools.map((item, index) => {
        let icon =
          item.iconType === 'image' ? (
            <img src={item.icon} alt={item.name + 'icon'} width={56} height={56} />
          ) : (
            <FJSvg src={item.icon} />
          );
        return (
          <li
            key={item.name}
            className={classname(
              Style.toolsItem,
              { [Style.toolsItemActive]: this.state.activeIndex === index },
              { [Style[item.className]]: this.state.activeIndex === index },
            )}
            onClick={() => itemClick(item, index)}
          >
            {icon}
            <div className={Style.toolsItemTitle}>{item.name}</div>
            <p className={Style.toolsItemDesc}>{item.desc}</p>
          </li>
        );
      })
    );
  }

  __drawRight() {
    const { data = {}, language = '' } = this.props;
    const arr = data.tools || [];
    switch (this.state.activeIndex) {
      case 0:
        return <AIVideoGenerator language={language} data={arr[this.state.activeIndex] || {}} />;
      case 1:
        return <TextToSpeech language={language} data={arr[this.state.activeIndex] || {}} />;
      case 2:
        return <AIScript language={language} data={arr[this.state.activeIndex] || {}} />;
      case 3:
        return <AIBGRemover language={language} data={arr[this.state.activeIndex] || {}} />;
      case 4:
        return <AutoSubtitle language={language} data={arr[this.state.activeIndex] || {}} />;
      case 5:
        return <Translate language={language} data={arr[this.state.activeIndex] || {}} />;
      // return <CurveSpeed data={arr[this.state.activeIndex] || {}} />;
      default:
        return <AIVideoGenerator language={language} data={arr[this.state.activeIndex] || {}} />;
    }
  }

  componentDidMount() {
    const { useAnimation } = this.props;

    if (!useAnimation) {
      return;
    }
    const AnimationHandler = new FJSrcUtil.AnimationHandler();
    AnimationHandler.transformAnimation(
      this.choicenessLeftDom.current,
      [
        { transform: 'translateX(-50px)', opacity: 0 },
        { transform: 'translateX(0)', opacity: 1 },
      ],
      {
        duration: 1000,
        delay: 0,
        easing: 'linear',
        fill: 'forwards',
      },
      true,
    );

    AnimationHandler.transformAnimation(
      this.choicenessRightDom.current,
      [
        { transform: 'translateX(50px)', opacity: 0 },
        { transform: 'translateX(0)', opacity: 1 },
      ],
      {
        duration: 1000,
        delay: 0,
        easing: 'linear',
        fill: 'forwards',
      },
      true,
    );
  }

  componentWillUnmount() {
    const AnimationHandler = new FJSrcUtil.AnimationHandler();
    AnimationHandler.unobserve(this.choicenessLeftDom.current);
  }

  render() {
    const { useAnimation = true } = this.props;
    return (
      <div className={Style.choicenessContainer}>
        <div className={Style.choiceness}>
          <div className={Style.contentBox}>
            <div
              className={classname(Style.left, useAnimation ? Style.animationInit : '')}
              ref={this.choicenessLeftDom}
            >
              {this.__drawHTag()}
              <ul className={Style.toolsContainer}>{this.__drawToolsList()}</ul>
            </div>
            <div
              className={classname(
                Style.right,
                useAnimation ? Style.animationInit : '',
                Style['right' + this.state.activeIndex],
              )}
              ref={this.choicenessRightDom}
            >
              {this.__drawRight()}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
