import React, { Component } from 'react';

import Style from './AutoSubtitle.module.less';
import FJConfig from '../../../../src/config/FJConfig';

import FJUtil from '../../../../src/util/FJUtil';
import FJLocalStore from '../../../../src/store/FJLocalStore';

export default class AutoSubtitle extends Component {
  constructor(props) {
    super(props);

    this.timer1 = null;
    this.timer2 = null;

    this.inputAccept = [
      '3gp',
      'asf',
      'avi',
      'f4v',
      'flv',
      'hevc',
      'm2ts',
      'm2v',
      'm4v',
      'mjpeg',
      'mkv',
      'mov',
      'mp4',
      'mpeg',
      'mpg',
      'mts',
      'mxf',
      'ogv',
      'rm',
      'swf',
      'ts',
      'vob',
      'webm',
      'wmv',
      'wtv',
      '3g2',
      'mp3',
      'm4a',
      'wav',
      'wma',
      'flac',
      'aac',
      'ogg',
      'opus',
    ];
  }

  imgAnimation = e => {
    e.target.style.visibility = 'visible';
    e.target.style.opacity = '1';
    e.target.style.transform = 'translateX(0)';

    this.timer1 = setTimeout(() => {
      this.observerDom.style.visibility = 'visible';
      this.observerDom.style.opacity = '1';
      this.observerDom.style.transform = 'translateY(50px)';

      this.timer2 = setTimeout(() => {
        this.observerDomInput.style.visibility = 'visible';
        this.observerDomInput.style.opacity = '1';
        this.observerDomInput.style.top = '-110px';
      }, 300);
    }, 500);
  };

  componentWillUnmount() {
    this.timer1 && clearTimeout(this.timer1);
    this.timer2 && clearTimeout(this.timer2);
  }

  chooseFile = () => {
    this.inputRef.click();
  };

  jumpUrl(uploadKey, language = 'en') {
    FJUtil.ealog('home jump', {
      'click name': 'Al Auto Subtitle',
    });
    return FJUtil.getCurrentUrl() + '/editor/app?input_video=' + uploadKey;
  }

  saveFileThenJump(files) {
    const fileName = files[0].name;
    const fileType = fileName ? fileName.split('.').slice(-1)[0] : null;
    if (!this.inputAccept.includes(fileType.toLowerCase())) {
      return window.fjmessage && window.fjmessage.addMessage(FJLocalStore._('errFile'), 'error');
    }

    const params = {
      location: 'subtitle',
      showTrim: false,
    };

    FJUtil.readFiles(files, params, this.jumpUrl, 'files');
  }

  render() {
    const { data = {}, language = '' } = this.props;

    const imgPrefix = `https://resource.flexclip.com/pages/home/choiceness/${language === 'jp' ? 'jp-' : ''}`;
    const leftImg = imgPrefix + `AutoSubtitle-intro1.webp?v=${FJConfig.buildVersion}`;
    return (
      <div className={Style.container}>
        <div className={Style.video}>
          <video
            loop={true}
            autoPlay={true}
            muted={true}
            playsInline={true}
            className='observe-video'
            data-visible-play='true'
            preload='metadata'
            src={FJConfig.staticResourceServerAddress + `/pages/home/video/AutoSubtitle.mp4?v=${FJConfig.buildVersion}`}
            width={1018}
            height={668}
            onCanPlay={e => {
              this.imgAnimation(e);
            }}
          />
        </div>
        <div className={Style.inputBox} ref={e => (this.observerDomInput = e)}>
          <span>{data['input-desc'] || 'Videos are a great way to explain your product.'}</span>
        </div>
        <div className={Style.floatContainer} ref={e => (this.observerDom = e)}>
          <img
            draggable={false}
            className={Style.floatTopImg}
            src={leftImg}
            width={370}
            height={231}
            alt='Au to Subtitle intro1'
          />

          <div className={Style.buttonBox} onClick={this.chooseFile}>
            <img
              src='https://resource.flexclip.com/pages/home/choiceness/AutoSubtitle-intro2.svg'
              width={370}
              height={231}
              alt='Au to Subtitle intro2'
            />
            {data['button-1'] || 'Generate Subtitles'}
          </div>
        </div>

        <input
          ref={e => (this.inputRef = e)}
          type='file'
          accept={this.inputAccept.map(item => '.' + item).join(',')}
          style={{ display: 'none' }}
          onClick={e => {
            e.target.value = null;
          }}
          onChange={e => {
            this.saveFileThenJump(e.target.files);
          }}
        />
      </div>
    );
  }
}
